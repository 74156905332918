import { CssBaseline } from '@mui/material';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import packageJson from '../package.json';
import { Preload } from './components/Preload';
import { Layout42 } from './layouts/Layout42';
import { Layout43 } from './layouts/Layout43';
import { Layout44 } from './layouts/Layout44';

function App() {
  return (
    <BrowserRouter basename={packageJson.homepage}>
      <Routes>
        <Route
          path="/"
          element={
            <React.Fragment>
              <CssBaseline />
              <Preload />
              {/* <Layout42 /> */}
              {/* <Layout43 /> */}
              <Layout44 />
            </React.Fragment>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
