import { Button } from "@mui/material";
import {QRCodeSVG} from 'qrcode.react';
import { useEffect } from "react";

interface QRDialogProps {
  onClose: () => void;
}

const QR_CONTAINER_ID = 'qr-container'

export const QRDialog = ({onClose}: QRDialogProps) => {
  const qrCodeLink = window.location.href

  useEffect(() => {
    const qrContainer = document.getElementById(QR_CONTAINER_ID) 

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') onClose();
    }

    const handleClose = (e: MouseEvent) => {
      const target = e.target as HTMLButtonElement;
      if (target.id === QR_CONTAINER_ID) onClose();
    }

    if (qrContainer) {
      qrContainer.addEventListener('click', handleClose)
      document.addEventListener('keydown', handleKeyDown)

      return () => {
        qrContainer.removeEventListener('click', handleClose)
        document.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [onClose]) 

  return <div id={QR_CONTAINER_ID} style={{
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    height: '100vh',
    zIndex: 10,
    background: 'rgba(0, 0, 0, 0.5)',
    transition: 'background 1s ease',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '32px',
  }}>
    <div style={{
      width: '332px',
      height: '332px',
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <div style={{
        lineHeight: 0,
      }}>
        <QRCodeSVG
          size={300}
          value={qrCodeLink}
        />
      </div>
    </div>
    <p
      style={{
        fontWeight: 400,
        fontSize: '20px',
        lineHeight: '26px',
        color: 'white'
      }} >
      Scan this QR code using your smartphone to see the model in AR
    </p>
    <Button
      sx={{
        backgroundColor: 'white',
        border: '1px solid #F3F07D',
        color: 'black',
        '&:hover': {
          backgroundColor: '#F3F07D',
        }
      }}
    onClick={onClose} variant="contained">
      Back to viewer
    </Button>
  </div>
}