import { Engine, Scene, useScene } from 'react-babylonjs';
import {
  Vector3,
  Color4,
  CubeTexture,
  SceneLoader,
  AbstractMesh,
  ArcRotateCamera,
  PBRMaterial,
  BaseTexture,
  MeshBuilder,
  Color3,
  ShadowGenerator,
  StandardMaterial,
  DirectionalLight,
} from '@babylonjs/core';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { useAppSelector } from '../hooks/useAppSelector';
import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { store } from '../store';
import { activeFetchesInc, activeFetchesReset, setAnimationPlaying, setHideScene, setSceneInitCompleted } from '../slices/settingsSlice';
import '@babylonjs/loaders';
import { MAGIC_ZOOM_DISTANCE } from '../config';
import { ShadowOnlyMaterial } from '@babylonjs/materials/shadowOnly';

function collectTextures(jsonMaterial: any, material: PBRMaterial) {
  let allTextures: BaseTexture[] = [];

  if (jsonMaterial.ambientTexture && jsonMaterial.ambientTexture.base64String && jsonMaterial.ambientTexture.base64String.startsWith('http')) {
    allTextures.push(material.ambientTexture!);
  }

  if (jsonMaterial.bumpTexture && jsonMaterial.bumpTexture.base64String && jsonMaterial.bumpTexture.base64String.startsWith('http')) {
    allTextures.push(material.bumpTexture!);
  }

  if (jsonMaterial.albedoTexture && jsonMaterial.albedoTexture.base64String && jsonMaterial.albedoTexture.base64String.startsWith('http')) {
    allTextures.push(material.albedoTexture!);
  }

  if (jsonMaterial.metallicTexture && jsonMaterial.metallicTexture.base64String && jsonMaterial.metallicTexture.base64String.startsWith('http')) {
    allTextures.push(material.metallicTexture!);
  }

  return allTextures;
}

export function InternalScene() {
  const itemLoadFinish = useAppSelector((state) => state.settings.itemLoadFinish);
  const modelPath = useAppSelector((state) => state.settings.modelPath);
  const environmentPath = useAppSelector((state) => state.settings.environmentPath);
  const states = useAppSelector((state) => state.settings.states);
  const changeable = useAppSelector((state) => state.settings.changeable);
  const animations = useAppSelector((state) => state.settings.animations);
  const activeState = useAppSelector((state) => state.settings.activeState);
  const activeVariant = useAppSelector((state) => state.settings.activeVariant);
  const groundShadowAlpha = useAppSelector((state) => state.settings.groundShadowAlpha);
  const shadowBlurScale = useAppSelector((state) => state.settings.shadowBlurScale);
  const restrictUnderView = useAppSelector((state) => state.settings.restrictUnderView);
  const dispatch = useAppDispatch();

  const [currentState, setCurrentState] = useState(-1);
  const scene = useScene();

  function updateVariant(variant: number[]) {
    console.log('Updating variant');
    let loadingMaterials: any[] = [];
    let promises = [];
    for (let changeableCnt = 0; changeableCnt < changeable.length; changeableCnt++) {
      promises.push(
        fetch(changeable[changeableCnt].materials[variant[changeableCnt]], {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (jsonMaterial) {
            console.log(`Loaded material ${changeable[changeableCnt].materials[variant[changeableCnt]]}`);
            let material: PBRMaterial = PBRMaterial.Parse(jsonMaterial, scene!, '') || new PBRMaterial('');
            material.name = changeable[changeableCnt].materials[variant[changeableCnt]];
            loadingMaterials.push({ json: jsonMaterial, material: material, changeableCnt: changeableCnt });
          })
      );
    }
    Promise.all(promises).then(() => {
      console.log('All jsons are loaded');
      let allTextures: BaseTexture[] = [];
      loadingMaterials.forEach((element) => {
        allTextures = allTextures.concat(collectTextures(element.json, element.material));
      });
      BaseTexture.WhenAllReady(allTextures, () => {
        console.log('All textures are loaded');
        if (
          variant.length !== activeVariant.length ||
          !variant.every(function (value, index) {
            return value === activeVariant[index];
          })
        ) {
          console.log('Material changed while loading...');
          updateVariant([...activeVariant]);
        } else {
          loadingMaterials.forEach((element) => {
            let sphere = MeshBuilder.CreateSphere(`test_sphere`, { diameter: 0.000001 });
            sphere.material = element.material;
          });

          setTimeout(() => {
            loadingMaterials.forEach((element) => {
              scene!.meshes
                .filter((el) => changeable[element.changeableCnt].meshes.includes(el.name))
                .forEach((mesh) => {
                  mesh.material = element.material;
                });
            });
            setTimeout(() => {
              dispatch(setHideScene(false));
              dispatch(activeFetchesReset());
            }, 250);
          }, 250);
        }
      });
    });
  }

  useEffect(() => {
    console.log('Initializing scene');
    scene!.clearColor = new Color4(1, 1, 1, 1);
    dispatch(setSceneInitCompleted(true));
  }, [scene]);

  useEffect(() => {
    if (itemLoadFinish) {
      console.log('Setting scene');
      dispatch(activeFetchesInc());
      const envTexture = CubeTexture.CreateFromPrefilteredData(environmentPath, scene!);
      scene!.environmentTexture = envTexture;
      let slicedPath = modelPath.split('/');
      SceneLoader.ImportMeshAsync('', slicedPath.slice(0, slicedPath.length - 1).join('/') + '/', slicedPath[slicedPath.length - 1], scene).then(
        (result) => {
          dispatch(setHideScene(true));
          let worldsBoundaries: { min: Vector3; max: Vector3 } = scene!.getWorldExtends((mesh: AbstractMesh) => result.meshes.includes(mesh));
          let sceneCenter: Vector3 = Vector3.Center(worldsBoundaries.min, worldsBoundaries.max);
          let sceneRadius: number = Vector3.Distance(worldsBoundaries.min, worldsBoundaries.max);
          let camera = new ArcRotateCamera(
            'Camera',
            (3 * Math.PI) / 4,
            Math.PI / 3,
            (sceneRadius / 2) * 3 + 0.1 + MAGIC_ZOOM_DISTANCE,
            sceneCenter,
            scene!
          );
          camera.panningSensibility = 0;
          camera.wheelDeltaPercentage = 0.01;
          camera.pinchDeltaPercentage = 0.0004;
          camera.minZ = 0.1;
          if (restrictUnderView) {
            camera.lowerBetaLimit = 0;
            camera.upperBetaLimit = Math.PI / 2;
          }
          camera.upperRadiusLimit = sceneRadius * 3 + camera.minZ + MAGIC_ZOOM_DISTANCE;
          camera.lowerRadiusLimit = sceneRadius / 2 + camera.minZ + MAGIC_ZOOM_DISTANCE;
          camera.attachControl();

          var light = new DirectionalLight('dirLight', new Vector3(0, -1, 0), scene!);
          light.intensity = 0.0001;

          let ground = MeshBuilder.CreateGround('ground', { width: 1000, height: 1000 }, scene!);
          let ground_material = new ShadowOnlyMaterial('mat', scene!);
          ground_material.alpha = groundShadowAlpha;
          ground.material = ground_material;
          ground.receiveShadows = true;

          var sg = new ShadowGenerator(1024, light);
          sg.useBlurExponentialShadowMap = true;
          sg.blurScale = 32;

          for (let meshCnt = 0; meshCnt < scene!.meshes.length; meshCnt++) {
            console.log(scene!.meshes[meshCnt].name);
            if (scene!.meshes[meshCnt].name === '__root__') {
              console.log('pillow found');
              sg.addShadowCaster(scene!.meshes[meshCnt], true);
              scene!.meshes[meshCnt].receiveShadows = true;
            }
          }

          for (let animationCnt = 0; animationCnt < scene!.animationGroups.length; animationCnt++) {
            scene!.animationGroups[animationCnt].stop();
            scene!.animationGroups[animationCnt].reset();
          }

          for (let animationCnt = 0; animationCnt < scene!.animationGroups.length; animationCnt++) {
            scene!.animationGroups[animationCnt].onAnimationGroupEndObservable.add(() => {
              console.log('Animation finished');
              dispatch(setAnimationPlaying(false));
            });
          }

          if (states.length > 1) {
            for (let animationCnt = 0; animationCnt < animations.length; animationCnt++) {
              if (animations[animationCnt].from === states[activeState]) {
                scene!.animationGroups.filter((el) => el.name === animations[animationCnt].name)[0].reset();
                break;
              }
            }
            setCurrentState(activeState);
          }

          updateVariant([...activeVariant]);
        }
      );
    } else {
      console.log('Cleaning scene');
      dispatch(setHideScene(true));
      dispatch(setAnimationPlaying(false));
      setCurrentState(-1);
    }
  }, [itemLoadFinish]);

  useEffect(() => {
    if (itemLoadFinish && currentState !== -1) {
      console.log('Run state slow animation');
      for (let animationCnt = 0; animationCnt < animations.length; animationCnt++) {
        if (animations[animationCnt].from === states[currentState] && animations[animationCnt].to == states[activeState]) {
          scene!.animationGroups.filter((el) => el.name === animations[animationCnt].name)[0].play();
          dispatch(setAnimationPlaying(true));
        }
      }
      setCurrentState(activeState);
    }
  }, [activeState]);

  useEffect(() => {
    if (itemLoadFinish && scene!.meshes.length) {
      dispatch(activeFetchesReset());
      dispatch(activeFetchesInc());
      updateVariant([...activeVariant]);
    }
  }, [activeVariant]);
  return null;
}

export function BabylonScene() {
  const activeItem = useAppSelector((state) => state.settings.activeItem);
  const hideScene = useAppSelector((state) => state.settings.hideScene);

  return (
    <div className={`babylonjs-scene ${hideScene ? 'babylonjs-scene-hidden' : ''}`}>
      <Engine antialias adaptToDeviceRatio={true} canvasId="babylonJS">
        <Scene key={`babylonscene_${activeItem ? activeItem : ''}`}>
          <Provider store={store}>
            <InternalScene />
          </Provider>
        </Scene>
      </Engine>
    </div>
  );
}
