import { float } from '@babylonjs/core/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface SettingsState {
  itemConfigs: string[];
  itemPreviews: string[];

  modelPath: string;
  arModelsPath: string; // disable AR if empty
  environmentPath: string;
  changeable: {
    name: string;
    meshes: string[];
    materials: string[];
    names: string[];
    images: string[];
  }[];
  states: string[];
  animations: {
    from: string;
    to: string;
    name: string;
  }[];

  arDisabled: boolean;
  stateDisabled: boolean;
  itemDisabled: boolean;
  variantDisabled: boolean;

  activeItem: number;
  activeState: number;
  activeVariant: number[];

  animationPlaying: boolean;
  displayedChangeable: number;

  sceneInitCompleted: boolean;
  collectionLoadFinish: boolean;
  itemLoadFinish: boolean;

  firstItemLoad: boolean;
  activeFetches: number;
  hideScene: boolean;

  language: string;

  groundShadowAlpha: number;
  shadowBlurScale: number;

  restrictUnderView: boolean;
}

// Define the initial state using that type
const initialState: SettingsState = {
  itemConfigs: ['', ''],
  itemPreviews: ['b88a9374df.jpg', 'b88a9374df.jpg'],

  modelPath: '',
  arModelsPath: '',
  environmentPath: '',
  states: ['Open', 'Close'],
  changeable: [
    {
      name: 'Legs',
      meshes: ['a'],
      materials: ['/', '/'],
      names: ['Hello', 'World'],
      images: ['1eedfefd36.jpg', 'db9de05234.jpg'],
    },
    {
      name: 'Seat',
      meshes: ['a', 'b'],
      materials: ['/', '/', '/'],
      names: ['O', 'AAA', 'CCC'],
      images: ['b88a9374df.jpg', 'ed61f43461.jpg', 'b88a9374df.jpg'],
    },
  ],
  animations: [],

  arDisabled: true,
  stateDisabled: true,
  itemDisabled: true,
  variantDisabled: true,

  activeItem: -1,
  activeState: 0,
  activeVariant: [],

  animationPlaying: false,
  displayedChangeable: 0,

  sceneInitCompleted: false,
  collectionLoadFinish: false,
  itemLoadFinish: false,

  firstItemLoad: true,
  activeFetches: 0,
  hideScene: false,
  language: "en",

  groundShadowAlpha: 0.1,
  shadowBlurScale: 32,

  restrictUnderView: false,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    activeFetchesReset: (state) => {
      state.activeFetches = 0;
    },
    activeFetchesInc: (state) => {
      state.activeFetches += 1;
    },
    activeFetchesDec: (state) => {
      state.activeFetches -= 1;
    },
    setHideScene: (state, action: PayloadAction<boolean>) => {
      state.hideScene = action.payload;
    },
    setStates: (state, action: PayloadAction<string[]>) => {
      state.states = action.payload;
    },
    setSceneInitCompleted: (state, action: PayloadAction<boolean>) => {
      state.sceneInitCompleted = action.payload;
    },
    setAnimationPlaying: (state, action: PayloadAction<boolean>) => {
      state.animationPlaying = action.payload;
    },
    setCollectionConfig: (state, action: PayloadAction<{ itemConfigs: string[]; itemPreviews: string[]; queryItemDisabled: boolean }>) => {
      state.itemDisabled = action.payload.queryItemDisabled || action.payload.itemConfigs.length <= 1;
      state.itemConfigs = action.payload.itemConfigs;
      state.itemPreviews = action.payload.itemPreviews;
      state.collectionLoadFinish = true;
    },
    setActiveState: (state, action: PayloadAction<number>) => {
      if (Number.isInteger(action.payload) && 0 <= action.payload && action.payload < state.states.length) {
        state.activeState = action.payload;
      }
    },
    setActiveItem: (state, action: PayloadAction<number>) => {
      if (Number.isInteger(action.payload) && 0 <= action.payload && action.payload < state.itemConfigs.length) {
        if (state.activeItem !== action.payload) {
          state.itemLoadFinish = false;
        }
        state.activeItem = action.payload;
      }
    },
    setActiveVariant: (state, action: PayloadAction<{ changeable: number; material: number }>) => {
      if (
        Number.isInteger(action.payload.changeable) &&
        Number.isInteger(action.payload.material) &&
        0 <= action.payload.changeable &&
        action.payload.changeable < state.changeable.length &&
        0 <= action.payload.material &&
        action.payload.material < state.changeable[action.payload.changeable].materials.length
      ) {
        state.activeVariant[action.payload.changeable] = action.payload.material;
      }
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      if (action.payload) {
        state.language = action.payload;
      }
    },
    setDisplayedChangeable: (state, action: PayloadAction<number>) => {
      state.displayedChangeable = action.payload;
    },
    setItemConfig: (
      state,
      action: PayloadAction<{
        modelPath: string;
        arModelsPath: string;
        environmentPath: string;
        groundShadowAlpha: number;
        shadowBlurScale: number;
        restrictUnderView: boolean;
        changeable: {
          name: string;
          meshes: string[];
          materials: string[];
          names: string[];
          images: string[];
        }[];
        states: string[];
        animations: {
          from: string;
          to: string;
          name: string;
        }[];

        activeVariant: number[];

        state: number;
        variant: string;
        queryArDisabled: boolean;
        queryStateDisabled: boolean;
        queryVariantDisabled: boolean;
      }>
    ) => {
      state.modelPath = action.payload.modelPath;
      state.arModelsPath = action.payload.arModelsPath;
      state.environmentPath = action.payload.environmentPath;
      state.groundShadowAlpha = action.payload.groundShadowAlpha;
      state.shadowBlurScale = action.payload.shadowBlurScale;
      state.restrictUnderView = action.payload.restrictUnderView;
      state.changeable = action.payload.changeable;
      state.states = action.payload.states;
      state.animations = action.payload.animations;

      state.arDisabled = action.payload.queryArDisabled || !state.arModelsPath;

      state.displayedChangeable = 0;

      state.variantDisabled = true;
      if (!action.payload.queryVariantDisabled) {
        for (let changeableCnt = 0; changeableCnt < state.changeable.length; changeableCnt++) {
          if (state.changeable[changeableCnt].materials.length > 1) {
            state.variantDisabled = false;
          }
        }
      }

      state.activeVariant = new Array(state.changeable.length).fill(0);
      if (state.firstItemLoad && action.payload.variant) {
        let splitVariant = action.payload.variant.split('-').map((element) => {
          return Number(element);
        });
        if (splitVariant.length === state.changeable.filter((element) => element.materials.length > 1).length) {
          let allVariantNumbersAreCorrent = true;
          splitVariant.forEach((element) => {
            if (!Number.isInteger(element)) {
              allVariantNumbersAreCorrent = false;
            }
          });
          if (allVariantNumbersAreCorrent) {
            splitVariant.forEach((element, index) => {
              state.activeVariant[index] = element;
            });
          }
        }
      }

      state.activeState = 0;
      if (state.firstItemLoad && Number.isInteger(action.payload.state)) {
        state.activeState = action.payload.state;
      }

      state.stateDisabled = action.payload.queryStateDisabled || action.payload.states.length <= 1;

      state.itemLoadFinish = true;
      if (state.firstItemLoad) {
        state.firstItemLoad = false;
      }
    },
  },
});

export const {
  setStates,
  setCollectionConfig,
  setActiveState,
  setActiveItem,
  setActiveVariant,
  setItemConfig,
  setDisplayedChangeable,
  setSceneInitCompleted,
  setAnimationPlaying,
  activeFetchesInc,
  activeFetchesDec,
  activeFetchesReset,
  setHideScene,
  setLanguage
} = settingsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.settings.activeState;

export default settingsSlice.reducer;
