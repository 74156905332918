import { useEffect } from 'react';
import { useAppSelector } from '../hooks/useAppSelector';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { setActiveItem, setCollectionConfig, setItemConfig, setLanguage } from '../slices/settingsSlice';
import { useSearchParams } from 'react-router-dom';
import { STORAGE_URL } from '../config';
import urlJoin from 'url-join';

export function Preload() {
  const [queryParameters] = useSearchParams();
  const collectionLoadFinish = useAppSelector((state) => state.settings.collectionLoadFinish);
  const itemConfigs = useAppSelector((state) => state.settings.itemConfigs);
  const activeItem = useAppSelector((state) => state.settings.activeItem);
  const sceneInitCompleted = useAppSelector((state) => state.settings.sceneInitCompleted);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setLanguage(queryParameters.get('lang') || 'en'));

    if (sceneInitCompleted) {
      fetch(urlJoin(STORAGE_URL, queryParameters.get('collection') || '', 'collection.json'), {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (jsonConfig) {
          console.log('Collection config loaded');
          dispatch(setCollectionConfig({ ...jsonConfig, queryItemDisabled: Boolean(queryParameters.get('item_disabled')) }));
        });
    }
  }, [sceneInitCompleted]);

  useEffect(() => {
    if (collectionLoadFinish) {
      dispatch(setActiveItem(Number(queryParameters.get('item'))));
    }
  }, [collectionLoadFinish]);

  useEffect(() => {
    if (collectionLoadFinish && itemConfigs[activeItem]) {
      fetch(itemConfigs[activeItem] || '', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (jsonConfig) {
          console.log(`Item ${activeItem} config loaded`);
          dispatch(
            setItemConfig({
              ...jsonConfig,
              restrictUnderView: Boolean(queryParameters.get('restrict_under_view')),
              state: Number(queryParameters.get('state')),
              variant: queryParameters.get('variant'),
              queryArDisabled: Boolean(queryParameters.get('ar_disabled')),
              queryStateDisabled: Boolean(queryParameters.get('state_disabled')),
              queryVariantDisabled: Boolean(queryParameters.get('variant_disabled')),
            })
          );
        });
    }
  }, [activeItem]);

  return null;
}
